import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    Name: 'Борис Гудков',
    Content:
      'Сотрудничаю с Кириллом уже не первый раз. Все сделано качественно и в срок. Рекомендую!',
  },
  {
    Name: 'Григорий Дядиченко [dyadichenkoga64]',
    Content: 'Всё супер. Быстро и качественно.',
  },
  {
    Name: '[eric-london]',
    Content:
      'Отличный исполнитель,выполнил работу быстро, качественно и быстрее заданного срока!',
  },
  {
    Name: '[bus_versal]',
    Content:
      'Отличная работа! Все сделано быстро и качественно, надеюсь на дальнейшее сотрудничество!',
  },
  {
    Name: 'Борис Гудков',
    Content:
      'Все было сделано вовремя. Все пожелания и правки были учтены.Сотрудничеством доволен. Рекомендую!',
  },
  {
    Name: '[john903]',
    Content:
      'Отличная работа. Всё выполнено как надо. На все замечания мастер отреагировал спокойно и быстро всё исправил. К слову сказать – замечаний было минимум.',
  },
  {
    Name: 'Николай Семенов',
    Content:
      'Ответственный, знающий своё дело специалист. Быстро выполнил мой заказ, и без проблем сделал доработку. Рекомендую',
  },
]

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
})

export default reviewsSlice.reducer
